import './ParallaxBackground.scss'

import Bowser from 'bowser'
import throttle from 'lodash/throttle'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

const Parallax = ({ children, factor, className }) => {
  const parallaxWrap = useRef(null)
  const parallaxContent = useRef(null)

  const isInvalidBrowser = useMemo(() => {
    if (typeof window !== 'undefined') {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.satisfies({
        'Internet Explorer': '>0',
        'Microsoft Edge': '<45',
      })
    }
  }, [])

  const animateEl = useCallback(() => {
    if (!isInvalidBrowser) {
      const wrapPos =
        parallaxWrap.current &&
        parallaxWrap.current.getBoundingClientRect().top
      const wrapHeight =
        parallaxWrap.current &&
        parallaxWrap.current.getBoundingClientRect().height
      const calcOffset = (0 - wrapPos * factor) * 0.083
      const inView = 0 - wrapPos / wrapHeight
      if (inView < 1.1 && inView > -1.1) {
        window.requestAnimationFrame(() => {
          parallaxContent.current.style.transform = `translate3d(0, ${calcOffset}rem, 0)`
        })
      }
    }
  }, [isInvalidBrowser, parallaxWrap, factor])

  useEffect(animateEl, [animateEl])

  const handleAnimateEl = throttle(animateEl, 16)

  useEffect(() => {
    window.addEventListener('scroll', handleAnimateEl, {
      passive: true,
    })
    return () => {
      window.removeEventListener('scroll', handleAnimateEl, {
        passive: true,
      })
    }
  }, [handleAnimateEl])

  return (
    <div
      className={`parallax-bg-wrap ${className || ''}`}
      ref={parallaxWrap}
    >
      <div className="parallax-bg-wrap-inner">
        <div className={'parallax-bg'} ref={parallaxContent}>
          <div className="parallax-bg-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

Parallax.defaultProps = {
  factor: 0.25,
}

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  factor: PropTypes.number,
}

export default Parallax
