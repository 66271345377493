import './ImageHero.scss'

import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

import ParallaxBackground from '../components/ParallaxBackground'

const ImageHero = ({ children, fluid, alt }) => {
  return (
    <section id="hero" className="image-hero">
      <div className="content">{children}</div>
      <div className="image-hero-background">
        <ParallaxBackground>
          <Img fluid={fluid} alt={alt} />
        </ParallaxBackground>
      </div>
    </section>
  )
}

export default ImageHero

ImageHero.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  alt: PropTypes.string,
}
