import '../styles/careers.scss'

import { graphql } from 'gatsby'
import React from 'react'

// import CareerPostings from '../components/CareerPostings'
import ImageHero from '../components/ImageHero'
import SEO from '../components/SEO'
import Layout from '../layouts/Layout'

export const data = graphql`
  query {
    page: datoCmsCareersPage {
      heroHeadline
      heroBody
      heroBodyNode {
        childMarkdownRemark {
          html
        }
      }
      heroCtaText
      heroCtaUrl
      heroCtaSecondaryNode {
        childMarkdownRemark {
          html
        }
      }
    }
    hero: file(relativePath: { eq: "careers_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroTablet: file(relativePath: { eq: "careers_hero_tablet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CareersPage = ({ data }) => {
  const { page } = data
  const heroImage = [
    {
      ...data.hero.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
    {
      ...data.heroTablet.childImageSharp.fluid,
      media: `(max-width: 1024px)`,
    },
  ]
  return (
    <Layout id="careers">
      <SEO title={page.heroHeadline} description={page.heroBody} />
      <ImageHero fluid={heroImage} alt="Capsules dissolving in water">
        <h1>{page.heroHeadline}</h1>
        <div
          className="lede"
          dangerouslySetInnerHTML={{
            __html: page.heroBodyNode.childMarkdownRemark.html,
          }}
        />
        <a
          href={page.heroCtaUrl}
          className="cta"
          target="_blank"
          rel="noreferrer"
        >
          {page.heroCtaText}
        </a>
        <div
          className="secondary-cta"
          dangerouslySetInnerHTML={{
            __html: page.heroCtaSecondaryNode.childMarkdownRemark.html,
          }}
        />
      </ImageHero>
      {/* <CareerPostings /> */}
    </Layout>
  )
}

export default CareersPage
